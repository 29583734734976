import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  Content,
  MultipleComponentIterator,
  FullImageCard,
  InternalLink,
  FishermanIcon,
  MarkdownContent,
  BackgroundImageTeamMember,
  SocialMediaV2,
  BasicCarousel,
  componentIterator,
  HorizontalImageCard,
  BlogPostLink,
  FormattedDate,
  ExpandableText,
} from "@bluefin/components";
import { Grid, Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
} from "../utils/utils";
import {
  createServiceCategorySlug,
  createTeamMemberSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessFile,
      allFishermanBusinessTeam,
      allFishermanBusinessBlogPost,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <Image
            src={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
            className={"image hero image-overlay"}
            background={true}
          >
            <Grid
              className={"home-section-container"}
              stackable={true}
              textAlign={"center"}
            >
              <Grid.Column width={16}>
                <Header
                  as={"h1"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "home_section_header",
                    defaultValue: "Home",
                  })}
                />
                <Content textAlign={"center"}>
                  <Content.Markup
                    width={8}
                    isMarkdown={true}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "home_section_description",
                    })}
                    extra={
                      <div className={"ctas-container"}>
                        <Button
                          primary={true}
                          as={"a"}
                          href={"javascript:blvd.openBookingWidget();"}
                        >
                          Book Now
                        </Button>
                      </div>
                    }
                  />
                </Content>
              </Grid.Column>
            </Grid>
          </Image>
          <div className={"services-section"}>
            <Card.Group className={"services"} itemsPerRow={3} stackable={true}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessMenuCategory.nodes}
                components={[
                  {
                    component: (
                      <FullImageCard
                        link={true}
                        as={InternalLink}
                        event={{
                          category: "Services",
                          action: "View Category Details",
                        }}
                      />
                    ),
                    propMap: {
                      key: "name",
                      image: withNamedArgs({
                        func: getBusinessFiles,
                        args: {
                          businessFiles: allFishermanBusinessFile.nodes,
                          numToSelect: 1,
                        },
                      }),
                      "event.label": "name",
                      to: createServiceCategorySlug,
                    },
                    children: [
                      {
                        component: <Card.Header />,
                        propMap: { content: "name", key: "name" },
                      },
                      { component: <FishermanIcon iconName={"arrow-right"} /> },
                    ],
                  },
                ]}
                limit={3}
              />
            </Card.Group>
            <Button
              primary={true}
              to={"/services/"}
              event={{ category: "Services", action: "View All Services" }}
              as={InternalLink}
            >
              See all Services
            </Button>
          </div>
          <div className={"about-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={8} className={"markdown-column"}>
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_section_description",
                  })}
                />
              </Grid.Column>
              <Grid.Column width={8} className={"image-column"}>
                <Image
                  className={"image"}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Content.Image",
                    componentIdentifier: "about_section_image",
                    numToSelect: 1,
                  })}
                />
              </Grid.Column>
            </Grid>
          </div>
          <div className={"stylists-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_header",
                defaultValue: "OUR TOP STYLISTS",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_description",
              })}
            />
            <Card.Group className={"featured-stylists"} itemsPerRow={3}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessTeam.nodes}
                limit={3}
                components={[
                  {
                    component: (
                      <BackgroundImageTeamMember
                        useModal={false}
                        as={InternalLink}
                      />
                    ),
                    propMap: {
                      "teamMember.name": "name",
                      "teamMember.role": "role",
                      "teamMember.photo": "gatsbyImage",
                      "teamMember.social": "socialMedia",
                      "teamMember.description": "description",
                      "teamMember.email": "email",
                      "teamMember.phone": "phone",
                      "teamMember.locations": "locations",
                      "teamMember.hours": "hours",
                      to: createTeamMemberSlug,
                    },
                  },
                ]}
              />
            </Card.Group>
            <Button
              to={"/team/"}
              primary={true}
              event={{ category: "Team", action: "View All Team Members" }}
              as={InternalLink}
            >
              See More
            </Button>
          </div>
          <div className={"image-gallery-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_header",
                defaultValue: "WE'RE SOCIAL",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_description",
              })}
            />
            <SocialMediaV2>
              <MultipleComponentIterator
                iterator={fishermanBusiness.socialMedia}
                components={[
                  {
                    component: <SocialMediaV2.Platform />,
                    propMap: { key: "_id", url: "link", platform: "type" },
                    children: [
                      {
                        component: <SocialMediaV2.Icon />,
                        propMap: { icon: "type" },
                      },
                      {
                        component: <SocialMediaV2.Handle />,
                        propMap: { link: "link" },
                      },
                    ],
                  },
                ]}
              />
            </SocialMediaV2>
            <BasicCarousel
              className={"image-gallery"}
              size={4}
              grouping={2}
              displayIndicators={true}
              displayPlayPauseButton={true}
              autoScroll={true}
              tablet={{}}
              mobile={{}}
              animation={{
                name: "blur",
                duration: 200,
                transitionProps: {
                  preAnimating: { opacity: 0.99, blur: "2px" },
                  animating: { opacity: 0.99, blur: "2px" },
                },
              }}
              items={componentIterator({
                iterator: getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "ImageCollection",
                  componentIdentifier: "image_gallery",
                }),
                component: (
                  <Image background={true} className={"gallery-image-item"} />
                ),
                propMap: { src: "__all__" },
              })}
            />
          </div>
          <div className={"blogs-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_header",
                defaultValue: "LATEST ARTICLES",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_description",
              })}
            />
            <Card.Group
              className={"featured-blogs"}
              itemsPerRow={2}
              stackable={true}
            >
              <MultipleComponentIterator
                iterator={allFishermanBusinessBlogPost.nodes}
                limit={2}
                components={[
                  {
                    component: (
                      <HorizontalImageCard
                        imagePosition={"left"}
                        link={true}
                        event={{ category: "Blog", action: "View Blog Post" }}
                      />
                    ),
                    propMap: {
                      key: "_id",
                      image: "gatsbyHeroImage",
                      "event.label": "title",
                    },
                    children: [
                      {
                        component: <BlogPostLink />,
                        propMap: { key: "_id", slug: "slug" },
                        children: [
                          {
                            component: <Card.Header className={"title"} />,
                            propMap: { content: "title" },
                          },
                          {
                            component: <FormattedDate outputFormat={"PPP"} />,
                            propMap: { date: "publishedAt" },
                          },
                          {
                            component: (
                              <ExpandableText
                                charLimit={160}
                                disableExpanding={true}
                              />
                            ),
                            propMap: { text: "summary" },
                          },
                          {
                            component: (
                              <FishermanIcon iconName={"arrow-right"} />
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
            <Button
              to={"/blog/"}
              primary={true}
              as={InternalLink}
              event={{ category: "Blog", action: "View All Blog Posts" }}
            >
              See all Articles
            </Button>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        _id
        link
        type
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        description
        name
        _id
        files
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
    allFishermanBusinessBlogPost(filter: { destination: { eq: "BLOG" } }) {
      nodes {
        _id
        title
        slug
        author {
          firstName
          lastName
          avatar
          email
        }
        summary
        content
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
      }
    }
  }
`;
